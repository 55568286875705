/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {


        //Page scroll
        $("li.page-scroll a, a.page-scroll").bind("click", function (e) {
          var offset = 0,//$('header.header').outerHeight(),
              t = $(this);

          if ($('body').hasClass('home') || t.hasClass('hero-down')) {

            if (t.hasClass('hero-down')) {
              var href = '#'+$('section#hero-section').next('span.section-anchor').attr('id');
            } else {
              var href = t.attr("href").replace('/', '');
            }

            $("html, body").stop().animate({
              scrollTop: (parseInt($(href).offset().top) - offset)
            }, 1500, "easeInOutExpo");
            e.preventDefault();
          } else {
            window.location.href = t.attr("href");
            return false;
          }

        });

        // on scroll
        $(window).on('scroll', function () {
          if ($(this).scrollTop() > 5) {
            $('.header').addClass("active");
          } else {
            $('.header').removeClass("active");
          }

          $('main > span.section-anchor').each(function () {
            var offset = parseInt($('header.header').height()) - 30;
            var ost = (parseInt($(this).offset().top) - offset);
            var h = $(this).next('section').outerHeight();
            var st = $(window).scrollTop();
            var id = $(this).attr('id');

            if ((st >= ost) && (st <= (ost + h))) {
              $('ul#menu-main-menu li a[href="/#' + id + '"]').parent('li').addClass('section-active');
            } else {
              $('ul#menu-main-menu li a[href="/#' + id + '"]').parent('li').removeClass('section-active');
            }

            if ((st + $(window).height()) === $(document).height()) {
              $('ul#menu-main-menu li').removeClass('section-active');
              $('ul#menu-main-menu li a[href="/#'+$('main > span.section-anchor').last().attr('id')+'"]').parent('li').addClass('section-active');
            }

          });
        });


        //Mobile nav menu click
        $(document).on('click', 'div.hamburger', function () {
          $(this).toggleClass('is-active');
          $('div.navbar-collapse').toggleClass('collapse');
        });


        //Close mobile nav on nav item click
        $(document).on('click', 'a.nav-link', function(){
          $('div.navbar-collapse').toggleClass('collapse');
          $('div.hamburger').removeClass('is-active');
        });

        //Close the mobile menu on body click
        $(document).on('click', 'body > main', function () {
          if ($('div.hamburger').hasClass('is-active')) {
            $('div.hamburger').trigger('click');
          }
        });





        /*imagesLoaded('div.hero-bg', {background: true}, function () {
          $('div.hero-bg').addClass('loaded');
          $('div.hero-bg').next('div.wrapper').addClass('show-wrapper');

          if ($('#booking-preloader').length) {

            setTimeout(function () {
              $('#booking-preloader').fadeTo('500', '0000.1', function(){
                $(this).remove();


              });
            }, 1250);

          }
        });*/


        //Video popups
        $('.video').magnificPopup({
          type: 'iframe',
          preloader: true,
          removalDelay: 500, //delay removal by X to allow out-animation
          callbacks: {
            beforeOpen: function () {
              // just a hack that adds mfp-anim class to markup
              this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
              this.st.mainClass = this.st.el.attr('data-effect');
            }
          },
          closeOnContentClick: true
        });//end video modal



        //Image popups
        $('.magnific-gallery').each(function () {
          $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            preloader: true,
            gallery: {
              enabled: true
            },
            removalDelay: 500, //delay removal by X to allow out-animation
            callbacks: {
              beforeOpen: function () {
                // just a hack that adds mfp-anim class to markup
                this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
                this.st.mainClass = this.st.el.attr('data-effect');
              }
            },
            closeOnContentClick: true,
            midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
          });
        });//end image modal



        //Image carousel
        $('.image-carousel').owlCarousel({
          items: 1,
          dots: false,
          nav: true,
          navText: ['<img src="/wp-content/themes/WordPress-Boilerplate/dist/images/icons/chevron-left.png" alt="Arrow left"/>', '<img src="/wp-content/themes/WordPress-Boilerplate/dist/images/icons/chevron-right.png" alt="Arrow left"/>']
        });//end img carousel




        $('div.content-blocks-carousel').owlCarousel({
          items: 3,
          dots: true,
          nav: false,
          loop: true,
          center: true,
          margin: 15,
          responsiveClass:true,
          responsive: {
            0: {
              items: 1,
            },
            768: {
              items: 2,
            },
            1025: {
              items: 3,
            }
          }
        });


        $('div.logos-carousel').owlCarousel({
          items: 8,
          dots: true,
          nav: false,
          margin: 15,
          loop: true,
          autoplay: true,
          responsiveClass:true,
          responsive: {
            0: {
              items: 3,
            },
            768: {
              items: 5,
            },
            1025: {
              items: 8,
            }
          }
        });







        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
